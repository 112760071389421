import { mapState, mapMutations } from "vuex";
import { serverApi } from "@/api/server";
import { isEmpty } from "lodash";

export const slagsMixin = {
    data() {
        return {
            slagTitle: "Шлакообразующие материалы",
            mixtureTitle: "Шихта",
        }
    },
    computed: {
        ...mapState(["slagItems", "loadingSlags"]),
        slags() {
            return this.slagItems.filter((x) => x.si == null);
        },
        mixture() {
            return this.slagItems.filter((x) => x.si != null);
        },
    },
    methods: {
        ...mapMutations(["SET_STATE"]),
        async edit(item) {
            if (item.id) {
                let slag = this.slagItems.find((x) => x.id === item.id);
                if (!isEmpty(slag)) {
                    let keys = Object.keys(slag);

                    for (let key of keys) {
                        slag[key] = item[key];
                    }
                }
            } else {
                this.slagItems.push(item);
            }

            try {
                const response = await serverApi.setSlag(item);

                if (response && response.data === "error") this.$error();
            } catch (e) {
                this.SET_STATE({ error: e });
            }
        },
    },
}