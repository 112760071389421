import axios from 'axios'
import Vue from 'vue'

export const http = axios.create({
    baseURL: process.env.VUE_APP_SERVER_URL
});

export async function getData(path, payload, handler, callback = null) {
    http.get(path, { params: payload })
        .then(res => {
            handler(res)
        }).catch(error => {
            console.log(error)
            notify()
        }).finally(() => {
            if (callback) callback()
        })
}

export function postData(path, payload, handler, callback) {
    http.post(path, payload)
        .then(res => {
            handler(res)
        }).catch(error => {
            console.log(error)
            notify()
        }).finally(() => {
            if (callback) callback()
        })
}

function notify() {
    Vue.notify({
        group: 'foo',
        type: "error",
        title: 'Произошла ошибка',
        text: 'Что-то пошло не так...'
    })
}