export const ferrosMixin = {
    methods: {
        mapFerros(ferroalloys) {
            if (!ferroalloys || !ferroalloys.length || !Array.isArray(ferroalloys)) return

            return ferroalloys.map((x) => ({
                mass: x.mass,
                minMass: x.min_mass,
                isUsed: x.isUse,
                id: x.id,
                shortname: x.shortname,
                name: x.name,
                price: x.price,
                comp: this.getComp(x.elements),
                off: x.off,
                useroff: x.useroffrole,
            }));
        },
        getComp(elements) {
            return Object.entries(elements)
                .filter(([key, value]) => value > 0)
                .map(([key, value]) => this.$capitalize(key) + "=" + this.$round(value))
                .join("; ");
        },
    }
}