import { authApi } from '@/api/auth'

const defaultState = () => {
    return {
        user: {},
        is_admin: false,
        login_error: {
            error: false,
            errorMessage: "Не верный логин или пароль",
        },
        license: {
            exp_date: ""
        }
    }
}

export default {
    state: defaultState,
    mutations: {
        SET_USER(state, user) {
            state.user = user
        },
        SET_ADMIN(state, isAdmin) {
            state.is_admin = isAdmin
        },
        SET_LOGIN_ERROR(state, error) {
            state.login_error.error = error
        },
        SET_LICENSE_EXPDATE(state, expDate) {
            state.license.exp_date = expDate
        },
        RESET_STATE(state) {
            Object.assign(state, defaultState())
        }
    },
    actions: {
        async login({ dispatch, commit, state }, { name, password }) {
            try {
                const response = await authApi.loginUser({ name, password })

                if (response.data && Object.keys(response.data).length) {
                    commit("SET_USER", response.data);

                    if (state.user.role === 4) commit("SET_ADMIN", true);
                } else if (!JSON.parse(response.data)) {
                    commit('SET_LOGIN_ERROR', true)
                }
            } catch (e) {
                commit('SET_STATE', { error: e })
                throw e
            }
        },
        async logout({ dispatch, commit }) {
            try {
                await authApi.logout()
                commit('RESET_STATE')
            } catch (e) {
                commit('SET_STATE', { error: e })
                throw e
            }
        },
        async getUser({ dispatch, commit, state }) {
            try {
                const response = await authApi.getCurrentUser()
                commit("SET_USER", response.data);

                if (state.user.role === 4) commit("SET_ADMIN", true);
            } catch (e) {
                commit('SET_STATE', { error: e })
                throw e
            }
        },
        async setLicenseKey({ dispatch, commit }, { key }) {
            try {
                const response = await authApi.setLicenseKey({ key })

                if (response.data && response.data === "error") commit('SET_STATE', { error: response.data })
            } catch (e) {
                commit('SET_STATE', { error: e })
                throw e
            }
        },
        async getLicenseExpDate({ dispatch, commit }) {
            try {
                const response = await authApi.getLicenseExpDate()

                if (response.data) {
                    if (response.data === "error") commit('SET_STATE', { error: response.data })
                    else commit('SET_LICENSE_EXPDATE', response.data)
                }
            } catch (e) {
                commit('SET_STATE', { error: e })
                throw e
            }
        },
    }
}