<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on" @click="openDialog"
          >mdi-cog-outline</v-icon
        >
      </template>

      <v-card>
        <v-toolbar dark color="cyan">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Настройки</v-toolbar-title>

          <template v-slot:extension>
            <v-tabs v-model="tab" align-with-title>
              <v-tabs-slider color="white"></v-tabs-slider>

              <v-tab v-for="item in items" :key="item.tab">
                {{ item.tab }}
              </v-tab>
            </v-tabs>
            <v-progress-linear
              :active="test_marks_loading"
              :indeterminate="test_marks_loading"
              absolute
              bottom
              color="primary"
            ></v-progress-linear>
            <div class="mr-5">
              <v-combobox
                :items="aggr_names"
                label="Установка"
                @change="handleAggrChange()"
                v-model="aggrName"
              ></v-combobox>
            </div>
            <div class="mr-5">
              <v-combobox
                :items="work_params.marks"
                label="Марка стали"
                @change="handleMarkChange()"
                v-model="markName"
              ></v-combobox>
            </div>
            <div class="mr-5">
              <v-combobox
                :items="work_params.letters"
                label="Письмо"
                @change="handleCompChange()"
                v-model="letterName"
              ></v-combobox>
            </div>
            <div class="mr-5">
              <v-combobox
                :items="work_params.composition"
                label="НД хим. состав"
                @change="handleCompChange()"
                v-model="compName"
              ></v-combobox>
            </div>
            <div>
              <v-combobox
                :items="setting_list"
                item-text="name"
                label="Настройки"
                @change="acceptDialog = true"
                v-model="settingName"
              ></v-combobox>
            </div>
            <v-dialog v-model="acceptDialog" max-width="320">
              <v-card>
                <v-card-title class="text-h5">
                  Применить настройки?
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn color="red darken-1" text @click="cancelSettings()">
                    Отменить
                  </v-btn>

                  <v-btn color="green darken-1" text @click="applySettings()">
                    Подтвердить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item.tab">
            <keep-alive>
              <component :is="item.content" :ranges="ranges"></component>
            </keep-alive>
          </v-tab-item>
        </v-tabs-items>

        <div
          class="settings-btn"
          v-show="ranges.length && ferros.length && !isLicenseComponent"
        >
          <v-btn
            color="primary"
            class="mb-2"
            @click="saveSettings"
            :loading="saving"
            :disabled="saving"
          >
            Сохранить
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { settingsApi } from "@/api/settings";
import { mapState, mapMutations, mapActions } from "vuex";
import { filtersMixin } from "@/mixins/mixins";
import FerroSettings from "./FerroSettings";
import Ranges from "./Ranges";
import LicenseSettings from "./LicenseSettings";
import { elements } from "@/constants/elements";
import { isEmpty } from "lodash";

export default {
  name: "Settings",
  mixins: [filtersMixin],
  components: {
    FerroSettings,
    Ranges,
    LicenseSettings,
  },
  data() {
    return {
      saving: false,
      dialog: false,
      acceptDialog: false,
      tab: null,
      initState: {
        min: {},
        max: {},
        opt: {},
        coef: {},
        ferros: [],
      },
    };
  },
  computed: {
    ...mapState([
      "melt_name",
      "aggr_name",
      "mark_name",
      "letter_name",
      "comp_name",
      "min",
      "max",
      "coef",
      "targets",
      "calculations",
      "ferros",
      "prediction",
      "test_marks_loading",
      "range",
      "steel_mass_error",
    ]),
    ...mapState({
      setting_list: (s) => s.settings.setting_list,
      selected_setting: (s) => s.settings.selected_setting,
      loading: (s) => s.settings.loading,
      is_admin: (s) => s.auth.is_admin,
    }),
    isLicenseComponent() {
      return this.tab === 2;
    },
    items() {
      let output = [
        { tab: "Диапазоны", content: "Ranges" },
        { tab: "Ферросплавы", content: "FerroSettings" },
      ];

      if (this.is_admin)
        output.push({
          tab: "Лицензия",
          content: "LicenseSettings",
        });

      return output;
    },
    settingName: {
      get() {
        return this.selected_setting;
      },
      set(value) {
        this.SET_SETTING(value);
      },
    },
    ranges() {
      if (!isEmpty(this.coef)) {
        return elements.map((el) => ({
          elem: this.$capitalize(el),
          min: !isEmpty(this.min) ? this.$round(this.min[el]) : null,
          max: !isEmpty(this.max) ? this.$round(this.max[el]) : null,
          opt: !isEmpty(this.targets) ? this.$round(this.targets[el]) : null,
          coef: this.coef[el],
        }));
      }

      return [];
    },
  },
  methods: {
    ...mapActions(["fetchSettings", "optimize"]),
    ...mapMutations(["SET_STATE", "SET_COEF", "SET_SETTING", "SET_SETTINGS"]),
    async closeDialog() {
      this.dialog = false;

      if (
        !this.isEqual(this.initState.min, this.min) ||
        !this.isEqual(this.initState.max, this.max) ||
        !this.isEqual(this.initState.opt, this.targets) ||
        !this.isEqual(this.initState.coef, this.coef) ||
        !this.isEqual(this.initState.ferros, this.ferros)
      ) {
        let payload = {
          delta: {},
          prediction: {},
          delta_ferros: [],
        };

        this.SET_STATE(payload);

        try {
          await this.saveSettings();
        } catch (e) {}

        if (this.range.length && !this.steel_mass_error.error) {
          try {
            await this.optimize();
          } catch (e) {}
        }
      }
    },
    openDialog() {
      if (!isEmpty(this.targets)) {
        this.initState.opt = { ...this.targets };
        this.initState.min = { ...this.min };
        this.initState.max = { ...this.max };
        this.initState.coef = { ...this.coef };
        this.initState.ferros = JSON.parse(JSON.stringify(this.ferros));
      }
    },
    cancelSettings() {
      this.acceptDialog = false;
      this.settingName = "";
    },
    async applySettings() {
      this.acceptDialog = false;

      await this.getSettings();
    },
    async getSettings() {
      let formData = {
        aggr: this.aggr_name,
        mark: this.mark_name,
        letter: this.letter_name,
        chemistry: this.comp_name,
        name_setting: this.selected_setting.id ?? "",
      };

      try {
        await this.fetchSettings(formData);
      } catch (e) {}
    },
    async saveSettings() {
      this.saving = true;

      let formData = {
        aggrigate: this.aggr_name,
        mark: this.mark_name,
        letter: this.letter_name,
        chemistry: this.comp_name,
        min: this.min,
        max: this.max,
        opt: this.targets,
        coef: this.coef,
        ferroalloys: this.ferros.map((x) => ({
          min_mass: x.minMass,
          isUse: x.isUsed,
          id: x.id,
          shortname: x.shortname,
          name: x.name,
          price: x.price,
          off: x.off,
          useroffrole: x.useroffrole,
        })),
      };

      try {
        let response = await settingsApi.saveSettings(formData);
        if (response.data && response.data === "OK") await this.getSettings();

        if (response.data && response.data === "error") this.$error();
      } catch (e) {
        this.SET_STATE({ error: e });
      } finally {
        this.saving = false;
      }
    },
    isEqual(first, second) {
      return JSON.stringify(first) == JSON.stringify(second);
    },
  },
};
</script>

<style scoped>
.settings-btn {
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 0 20px;
}
</style>