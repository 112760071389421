import { isEmpty } from "lodash";
import { mapActions, mapMutations, mapState } from "vuex";

export const filtersMixin = {
    data: () => ({
        testIntervId: null,
        meltIntervId: null,
        coefItems: [
            { text: "Все равны 1", value: false },
            { text: "Ручной ввод", value: true },
        ],
    }),
    computed: {
        ...mapState([
            "melt_name",
            "aggr_name",
            "work_params",
            "melts",
            "aggr_names",
            "range"
        ]),
        ...mapState({
            user: (s) => s.auth.user,
            is_admin: (s) => s.auth.is_admin,
        }),
        aggrName: {
            get() {
                return this.aggr_name;
            },
            set(value) {
                this.SET_STATE({ aggr_name: value });
            },
        },
        steelMeltName: {
            get() {
                return this.melt_name;
            },
            set(value) {
                this.SET_STATE({ melt_name: value });
            },
        },
        markName: {
            get() {
                return this.mark_name;
            },
            set(value) {
                this.SET_STATE({ mark_name: value });
            },
        },
        letterName: {
            get() {
                return this.letter_name;
            },
            set(value) {
                this.SET_STATE({ letter_name: value });
            },
        },
        compName: {
            get() {
                return this.comp_name;
            },
            set(value) {
                this.SET_STATE({ comp_name: value });
            },
        }
    },
    destroyed() {
        if (this.testIntervId) clearInterval(this.testIntervId);
        if (this.meltIntervId) clearInterval(this.meltIntervId);
    },
    methods: {
        ...mapMutations(["SET_STATE"]),
        ...mapActions([
            "fetchSettings",
            "getMarks",
            "getTestMarks",
            "updateTestMarks",
            "getSettingsList",
            'getMelting',
            "updateMelting",
            "getInfoData",
            "optimize"
        ]),
        async handleAggrChange() {
            this.aggrChangeEmpty();

            let payload = { aggr: this.aggr_name }

            try {
                await this.getMelting(payload)
                if (this.aggr_name) this.meltIntervId = setInterval(this.updateMelting, 10000);
                await this.fetchSettings(payload);
                await this.getSettingsList(payload);
            } catch (e) { }
        },
        async handleMeltChange() {
            this.meltChangeEmpty();

            try {
                await this.getMarks(this.coefItems);
                await this.getTestMarks();
                if (!this.is_admin && this.melt_name)
                    this.testIntervId = setInterval(this.updateTestMarks, 10000);
                await this.getElements();
                if (this.range.length && !this.steel_mass_error.error) await this.optimize()
            } catch (e) { }
        },
        async handleMarkChange() {
            try {
                await this.getInfoData()
                await this.handleCompChange()
            } catch (e) { }
        },
        async handleCompChange() {
            if (
                this.aggr_name &&
                this.mark_name &&
                this.letter_name &&
                this.comp_name
            )
                await this.getElements();
        },
        async getElements() {
            let formData = {
                aggr: this.aggr_name,
                mark: this.mark_name,
                letter: this.letter_name,
                chemistry: this.comp_name,
            };

            await this.fetchSettings(formData);
        },
        handleErrorMessage(response) {
            if (response.data && response.data === "error") this.$error();
        },
        aggrChangeEmpty() {
            if (this.meltIntervId)
                clearInterval(this.meltIntervId)

            let payload = {
                melt_name: "",
                mark_name: "",
                letter_name: "",
                comp_name: "",
                mass: "",
                delta: {},
                prediction: {},
                delta_ferros: [],
                range: [],
                test_marks: []
            };

            this.SET_STATE(payload);
        },
        meltChangeEmpty() {
            if (this.testIntervId)
                clearInterval(this.testIntervId)

            let payload = {
                delta: {},
                prediction: {},
                delta_ferros: [],
                range: [],
                test_marks: []
            };

            this.SET_STATE(payload);
        },
    }

} 