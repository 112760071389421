<template>
  <div>
    <v-data-table
      :headers="fHeaders"
      :items="ferros"
      :loading="ferros_loading"
      loading-text="Загрузка..."
      :items-per-page="10"
      item-key="id"
      class="elevation-1"
      :noDataText="noDataText"
      :footer-props="footerSettings"
    >
      <template v-slot:item.isUsed="{ item }">
        <v-simple-checkbox
          v-model="item.isUsed"
          :ripple="false"
          :disabled="isDisabled(item)"
          :class="[isDisabled(item) ? 'disabled' : '']"
        ></v-simple-checkbox>
      </template>
      <template v-slot:item.minMass="props" v-if="write">
        <v-edit-dialog
          large
          cancel-text="Отменить"
          save-text="Сохранить"
          @save="saveMinMass(props.item)"
          @open="editMinMass = props.item.minMass"
        >
          {{ props.item.minMass }}
          <template v-slot:input>
            <div class="mt-4 text-h6">Мин. масса, кг.</div>
            <v-text-field
              v-model="editMinMass"
              maxlength="10"
              required
              label="Edit"
              single-line
              counter
              :rules="[rules.digit]"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.price="props" v-if="write">
        <v-edit-dialog
          large
          cancel-text="Отменить"
          save-text="Сохранить"
          @save="savePrice(props.item)"
          @open="editPrice = props.item.price"
        >
          {{ props.item.price }}
          <template v-slot:input>
            <div class="mt-4 text-h6">Цена</div>
            <v-text-field
              v-model="editPrice"
              maxlength="10"
              required
              label="Edit"
              single-line
              counter
              :rules="[rules.digit]"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FerroSettings",
  data: function () {
    return {
      editPrice: "",
      editMinMass: "",
      rules: {
        digit: (value) => {
          return this.validate(value) || "Введите числовое значение";
        },
      },
      headers: [
        {
          text: "Использовать",
          sortable: false,
          value: "isUsed",
        },
        {
          text: "Отключил",
          sortable: false,
          value: "off",
        },
        {
          text: "Id",
          sortable: false,
          value: "id",
        },
        {
          text: "Сокращение",
          sortable: false,
          value: "shortname",
        },
        {
          text: "Наименование",
          sortable: false,
          value: "name",
        },
        {
          text: "Мин. масса, кг.",
          sortable: false,
          value: "minMass",
        },
        {
          text: "Цена",
          sortable: false,
          value: "price",
        },
        {
          text: "Хим. состав",
          sortable: false,
          value: "comp",
        },
      ],
      noDataText: "Данные отсутствуют",
      footerSettings: {
        itemsPerPageText: "Отображать",
        itemsPerPageAllText: "Все",
      },
    };
  },
  computed: {
    ...mapState(["ferros", "ferros_loading"]),
    ...mapState({
      user: (s) => s.auth.user,
    }),
    write() {
      const role = this.user.role;

      return role === 2 || role === 3 || role === 4;
    },
    fHeaders() {
      if (!this.write) return this.headers.filter((x) => x.value != "price");
      return this.headers;
    },
  },
  methods: {
    savePrice(item) {
      if (this.validate(this.editPrice)) item.price = this.editPrice;
    },
    saveMinMass(item) {
      if (this.validate(this.editMinMass)) item.minMass = this.editMinMass;
    },
    validate(value) {
      const pattern = /^\d+(\.\d+)?$/;
      return pattern.test(value);
    },
    isDisabled(item) {
      return this.user.role < item.useroffrole;
    },
  },
};
</script>

<style scoped>
.disabled {
  opacity: 0;
}
</style>