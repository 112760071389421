<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="ferros.filter((x) => x.isUsed)"
      :loading="ferros_loading"
      loading-text="Загрузка..."
      :items-per-page="10"
      show-select
      item-key="id"
      class="elevation-1"
      :noDataText="noDataText"
      :footer-props="footerSettings"
    >
      <template v-slot:top>
        <div class="align-right">
          <v-btn color="primary" dark class="mb-2" @click="addFerroalloys"
            >Прямой расчет</v-btn
          >
        </div>
      </template>
      <template v-slot:item.mass="props" v-if="write">
        <v-edit-dialog
          large
          cancel-text="Отменить"
          save-text="Сохранить"
          @save="saveMass(props.item)"
          @open="editMass = props.item.mass"
        >
          {{ props.item.mass }}
          <template v-slot:input>
            <div class="mt-4 text-h6">Масса</div>
            <v-text-field
              v-model="editMass"
              maxlength="10"
              required
              label="Edit"
              single-line
              counter
              :rules="[rules.digit]"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "FerroAlloys",
  data: function () {
    return {
      editMass: "",
      rules: {
        digit: (value) => {
          return this.validateMass(value) || this.massError.message;
        },
      },
      headers: [
        {
          text: "Масса, кг",
          sortable: false,
          value: "mass",
        },
        {
          text: "Сокращение",
          sortable: false,
          value: "shortname",
        },
        {
          text: "Наименование",
          sortable: false,
          value: "name",
        },
        {
          text: "Хим. состав",
          sortable: false,
          value: "comp",
        },
      ],
      massError: {
        error: false,
        message: "Введите числовое значение",
      },
      noDataText: "Данные отсутствуют",
      footerSettings: {
        itemsPerPageText: "Отображать",
        itemsPerPageAllText: "Все",
      },
    };
  },
  computed: {
    ...mapState(["additive", "ferros", "ferros_loading"]),
    ...mapState({
      user: (s) => s.auth.user,
    }),
    selected: {
      get() {
        return this.additive;
      },
      set(value) {
        this.SET_STATE({ additive: value });
      },
    },
    write() {
      const role = this.user.role;

      return role === 2 || role === 3 || role === 4;
    },
  },
  methods: {
    ...mapMutations(["SET_STATE"]),
    addFerroalloys() {
      this.$emit("addFerroalloys");
    },
    saveMass(item) {
      if (this.validateMass(this.editMass)) item.mass = this.editMass;
    },
    validateMass(value) {
      const pattern = /^[0-9]+$/;
      let valid = pattern.test(value);

      if (!valid) {
        this.massError.error = true;
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
.align-right {
  display: flex;
  justify-content: flex-end;
  padding: 20px 20px 0 20px;
}
</style>