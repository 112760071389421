<template>
  <v-card>
    <v-toolbar color="cyan" dark flat>
      <router-link to="/">
        <v-img
          alt="logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo.svg"
          transition="scale-transition"
        />
      </router-link>
      <template v-slot:extension>
        <settings />
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider color="white"></v-tabs-slider>

          <v-tab v-for="item in items" :key="item.tab">
            {{ item.tab }}
          </v-tab>
        </v-tabs>
      </template>

      <v-spacer></v-spacer>

      <v-menu bottom origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="cyan" dark v-bind="attrs" v-on="on">
            {{ user.fio }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <a
              href="#"
              class="black--text text-decoration-none"
              @click.prevent="logoutHandler"
            >
              <v-icon>mdi-clipboard-arrow-left</v-icon>Выйти
            </a>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.tab">
        <keep-alive>
          <component
            :is="item.content"
            @addFerroalloys="addFerroalloys"
          ></component>
        </keep-alive>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
// @ is an alias to /src
import {
  WorkParams,
  FerroAlloys,
  Analytics,
  SlagAnalytics,
  Settings,
} from "@/components/components";
import { mapActions, mapMutations, mapState } from "vuex";
import { serverApi } from "@/api/server";

export default {
  name: "Home",
  components: {
    WorkParams,
    FerroAlloys,
    Analytics,
    SlagAnalytics,
    Settings,
  },
  data: () => ({
    tab: null,
    items: [
      { tab: "Калькулятор", content: "WorkParams" },
      { tab: "Прямой расчет", content: "FerroAlloys" },
      { tab: "Аналитика", content: "Analytics" },
      { tab: "Расчет шлакообразующих", content: "SlagAnalytics" },
    ],
  }),
  computed: {
    ...mapState([
      "additive",
      "mass",
      "melt_name",
      "steel_mass_error",
      "aggr_name",
      "mark_name",
      "letter_name",
      "comp_name",
      "selected_coef",
    ]),
    ...mapState({
      user: (s) => s.auth.user,
    }),
  },
  methods: {
    ...mapMutations(["SET_STATE", "RESET_STATE"]),
    ...mapActions(["logout"]),
    addFerroalloys() {
      if (!this.melt_name || !this.mass)
        this.$error("Не введена плавка или масса стали");

      if (
        this.additive &&
        this.additive.length &&
        !this.steel_mass_error.error
      ) {
        let ferroMass = this.additive.map((x) => `${x.id}:${x.mass}`).join(";");

        const formData = {
          mass: this.mass,
          ferroMass,
          haet: this.melt_name,
          isHandCoef: this.selected_coef.value,
          aggr: this.aggr_name,
          mark: this.mark_name,
          letter: this.letter_name,
          chemistry: this.comp_name,
        };

        serverApi.getCalc(formData, (response) => {
          if (
            response.data &&
            response.data.length &&
            Array.isArray(response.data)
          ) {
            let payload = {
              delta: response.data[1][1],
              prediction: response.data[1][0],
              delta_ferros: response.data[0],
            };
            this.SET_STATE(payload);
          }

          this.handleErrorMessage(response);
        });
      } else {
        this.SET_STATE({ delta: [] });
      }
    },
    async logoutHandler() {
      try {
        await this.logout();
      } catch (e) {}

      this.$router.push("/login?message=logout");
    },
    handleErrorMessage(response) {
      if (response.data && response.data === "error") this.$error();
    },
  },
};
</script>

<style scoped>
.navbar-primary {
  background-color: #0b0f29;
}
</style>
