import store from "@/store"

export default {
    install(Vue, options) {
        Vue.prototype.$scale = function (value, element) {
            const scale = store.state.scale
            return value * (scale[element] || 100)
        }

        Vue.prototype.$deScale = function (value, element) {
            const scale = store.state.scale
            return value / (scale[element] || 100)
        }
    }
}