import { elements } from "@/constants/elements";

export const dataTable = {
    data: () => ({
        headers: []
    }),
    methods: {
        getHeader() {
            this.headers.push(
                ...elements.map((elem) => ({
                    text: elem[0].toUpperCase() + elem.substring(1),
                    value: elem,
                    sortable: false,
                }))
            );
        },
    }
}