<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3 class="mt-5" v-if="license_exp_date">
          Лицензия действительна до
          {{ license_exp_date | date }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h4 class="mt-5">Вы можете добавить новую лицензию</h4>
      </v-col>
    </v-row>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submitHandler"
    >
      <v-row>
        <v-col cols="12" sm="4" md="3" xl="2">
          <v-text-field
            v-model.trim="key"
            :rules="licenseRules"
            label="Ключ лицензии"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="3" xl="2">
          <v-btn
            type="submit"
            block
            :disabled="!valid"
            color="primary"
            class="mt-3 white--text"
            @click="validate"
          >
            Добавить
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      valid: true,
      licenseRules: [(v) => !!v || "Это поле необходимо заполнить"],
      key: "",
    };
  },
  computed: {
    ...mapState({
      license_exp_date: (x) => x.auth.license.exp_date,
    }),
  },
  async mounted() {
    try {
      await this.getLicenseExpDate();
    } catch (e) {}
  },
  methods: {
    ...mapActions(["getLicenseExpDate", "setLicenseKey"]),
    async submitHandler() {
      try {
        await this.setLicenseKey({ key: this.key });
        await this.getLicenseExpDate();

        this.$refs.form.reset();
      } catch (e) {}
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>

<style>
</style>