<template>
  <v-card>
    <v-card-title class="text-subtitle-1 d-flex justify-center">
      {{ title }}
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="slags"
      :loading="loading"
      loading-text="Загрузка..."
      :items-per-page="5"
      item-key="index"
      class="elevation-1"
      :noDataText="noDataText"
      :footer-props="footerSettings"
    >
      <template v-slot:footer>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <div class="align-right">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Добавить
                </v-btn>
              </div>
            </template>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="save"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ title }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="s.name"
                          label="Наименование"
                          :rules="nameRules"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" v-if="!mainCalc">
                        <v-text-field
                          type="number"
                          min="0"
                          v-model="s.mass"
                          label="Масса, т."
                          :rules="digitRules"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" v-if="mainCalc">
                        <v-text-field
                          v-model="s.maxMass"
                          :label="
                            !isMixture ? 'Доступная масса, т.' : 'Масса, т.'
                          "
                          :rules="!isMixture ? nameRules : digitRules"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          type="number"
                          min="0"
                          v-model="s.caO"
                          label="CaO, %"
                          :rules="digitRules"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          type="number"
                          min="0"
                          v-model="s.mgO"
                          label="MgO, %"
                          :rules="digitRules"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4" v-if="isMixture">
                        <v-text-field
                          type="number"
                          min="0"
                          v-model="s.si"
                          label="Si, %"
                          :rules="digitRules"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          type="number"
                          min="0"
                          v-model="s.siO2"
                          label="SiO2, %"
                          :rules="digitRules"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Отменить
                  </v-btn>
                  <v-btn
                    type="submit"
                    color="blue darken-1"
                    text
                    @click="validate"
                    :disabled="!valid"
                  >
                    Сохранить
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.resultMass="{ item }">
        <v-chip color="primary lighten 1" dark class="chip">
          {{ item.resultMass }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "Slags",
  props: {
    title: {
      type: String,
    },
    slags: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    isMixture: {
      type: Boolean,
    },
    mainCalc: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: false,
      nameRules: [(v) => !!v || "Это поле необходимо заполнить"],
      digitRules: [(v) => this.validateDigit(v) || "Введите числовое значение"],
      s: {
        name: "",
        mass: "",
        caO: "",
        mgO: "",
        si: null,
        siO2: "",
        maxMass: !this.isMixture ? "максимум" : "",
        resultMass: 0,
      },
      requiredMessage: "Это поле необходимо заполнить",
      noDataText: "Данные отсутствуют",
      footerSettings: {
        itemsPerPageText: "Отображать",
        itemsPerPageAllText: "Все",
      },
      valid: true,
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },
    save() {
      let s = { ...this.s };
      if (!s.mass) s.mass = 0;

      this.$emit("edit", s);
      this.close();
    },
    edit(item) {
      this.s = Object.assign({}, item);
      this.dialog = true;
    },
    validateDigit(value) {
      const pattern = /^\d+(\.\d+)?$/;
      return pattern.test(value);
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>

<style scoped>
.chip {
  width: 100%;
  justify-content: end;
}
</style>