<template>
  <v-card>
    <v-tabs vertical>
      <v-tab class="justify-start">Основной</v-tab>
      <v-tab class="justify-start">Прямой расчет</v-tab>

      <v-tab-item>
        <slag-main-calc />
      </v-tab-item>

      <v-tab-item>
        <slag-calc />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import SlagCalc from "./SlagCalc.vue";
import SlagMainCalc from "./SlagMainCalc.vue";

export default {
  name: "SlagAnalytics",
  components: {
    SlagCalc,
    SlagMainCalc,
  },
  async mounted() {
    try {
      await this.fetchSlag();
    } catch (e) {}
  },
  methods: {
    ...mapActions(["fetchSlag"]),
  },
};
</script>

<style>
.align-right {
  width: 100%;
  text-align: right;
}
.wrapper {
  display: flex;
  margin-bottom: 3px;
}
.slag {
  background-color: #3498db;
  color: white;
  width: 62px;
  display: inline-block;
  text-align: center;
}
.text {
  background-color: #ebe3e3;
  color: #000;
  font-size: 16px;
  font-weight: 900;
  padding: 0 5px;
  display: inline-block;
  min-width: 62px;
  text-align: center;
}
</style>