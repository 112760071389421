<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>Рабочие параметры</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="params-wrapper">
          <div class="params-item">
            <v-combobox
              :items="aggr_names"
              label="Установка"
              @change="handleAggrChange()"
              v-model="aggrName"
              :noDataText="noDataText"
            ></v-combobox>
          </div>
          <div class="params-item">
            <v-combobox
              :items="melts"
              label="Плавка"
              v-model="steelMeltName"
              :noDataText="noDataText"
            ></v-combobox>
          </div>
          <div class="params-item">
            <v-combobox
              :items="work_params.marks"
              label="Марка стали"
              @change="handleMarkChange()"
              v-model="markName"
              :noDataText="noDataText"
            ></v-combobox>
          </div>
          <div class="params-item">
            <v-combobox
              :items="work_params.letters"
              label="Письмо"
              @change="handleCompChange()"
              v-model="letterName"
              :noDataText="noDataText"
            ></v-combobox>
          </div>
          <div class="params-item">
            <v-combobox
              :items="work_params.composition"
              label="НД хим. состав"
              @change="handleCompChange()"
              v-model="compName"
              :noDataText="noDataText"
            ></v-combobox>
          </div>
          <div class="params-item">
            <v-combobox
              :items="coefItems"
              item-text="text"
              label="Коэф. усвоения"
              v-model="selectedCoef"
            ></v-combobox>
          </div>
          <div class="params-item">
            <v-text-field
              outlined
              label="Масса, т."
              v-model="steelMass"
              :rules="[rules.mass]"
            ></v-text-field>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" md="2" v-show="range.length">
        <v-btn
          color="#3498db"
          class="white--text"
          block
          :loading="calculating"
          :disabled="calculating"
          @click="handleOptimization"
          >Расчет</v-btn
        >
      </v-col>
      <v-col cols="6" md="2" v-show="range.length">
        <calc-history />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>Прогноз</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <prediction />
      </v-col>
    </v-row>

    <v-row v-show="range.length && delta_ferros.length">
      <v-col cols="12">
        <h2>Рекомендация</h2>
      </v-col>
    </v-row>
    <div v-show="range.length && delta_ferros.length">
      <rec :ferros="delta_ferros" :xl="2"></rec>
    </div>

    <v-row>
      <v-col cols="12">
        <h2>Пробы</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          v-model="selectedTestMark"
          :headers="headers"
          :items="test_marks"
          :loading="test_marks_loading"
          loading-text="Загрузка..."
          :disable-pagination="true"
          :hide-default-footer="true"
          :fixed-header="true"
          :height="tableHeight"
          :single-select="singleSelect"
          item-key="index"
          :show-select="is_admin"
          class="elevation-1"
          :noDataText="noDataText"
          :footer-props="footerSettings"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { filtersMixin, dataTable, rangeMixin } from "@/mixins/mixins";
import Prediction from "./Prediction";
import CalcHistory from "./CalcHistory";
import Rec from "./Rec";
import { isEmpty } from "lodash";

export default {
  name: "WorkParams",
  mixins: [filtersMixin, dataTable, rangeMixin],
  components: {
    Prediction,
    CalcHistory,
    Rec,
  },
  data() {
    return {
      noDataText: "Данные отсутствуют",
      footerSettings: {
        itemsPerPageText: "Отображать",
        itemsPerPageAllText: "Все",
      },
      singleSelect: true,
      showSelect: true,
      headers: [
        {
          text: "",
          sortable: false,
          value: "datestr",
        },
      ],
      diagramElems: [],
      rules: {
        mass: (value) => {
          return this.validateMass(value) || this.steel_mass_error.message;
        },
      },
    };
  },
  computed: {
    ...mapState([
      "mass",
      "mark_name",
      "letter_name",
      "comp_name",
      "targets",
      "test_marks",
      "selected_test_mark",
      "selected_coef",
      "delta_ferros",
      "steel_mass_error",
      "range",
      "test_marks_loading",
      "work_params",
      "calculating",
      "melt_name",
      "prediction",
    ]),
    ...mapState({
      user: (s) => s.auth.user,
      is_admin: (s) => s.auth.is_admin,
    }),
    tableHeight() {
      if (
        Array.isArray(this.test_marks) &&
        !isEmpty(this.test_marks) &&
        this.test_marks.length > 5
      )
        return 6 * 48;
      else undefined;
    },
    steelMass: {
      get() {
        return this.mass;
      },
      set(value) {
        this.SET_STATE({ mass: value });
      },
    },
    selectedTestMark: {
      get() {
        return this.selected_test_mark;
      },
      set(value) {
        this.SET_STATE({ selected_test_mark: value });
      },
    },
    selectedCoef: {
      get() {
        return this.selected_coef;
      },
      set(value) {
        this.SET_STATE({ selected_coef: value });
      },
    },
  },
  mounted() {
    this.getWorkParams();
    this.getHeader();
  },
  watch: {
    async steelMeltName(value) {
      if (!value) return;
      await this.handleMeltChange();
    },
  },
  methods: {
    ...mapMutations(["SET_STATE"]),
    ...mapActions(["getWorkParams", "optimize"]),
    localizeTime(date) {
      if (date) {
        return `${this.addZero(date.getHours())}:${this.addZero(
          date.getMinutes()
        )}:${this.addZero(date.getSeconds())}`;
      }
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    validateMass(value) {
      if (!value) return true;

      const pattern = /^\d+([\.,]\d+)?$/;
      let valid = pattern.test(value);

      if (!valid) {
        let steel_mass_error = {
          error: true,
          message: "Введите числовое значение",
        };

        this.SET_STATE({ steel_mass_error });
        return false;
      }

      this.steel_mass_error.error = false;

      return true;
    },
    async handleOptimization() {
      if (
        this.selected_test_mark &&
        this.selected_test_mark.length &&
        !this.steel_mass_error.error
      ) {
        try {
          await this.optimize();
        } catch (e) {}
      }
    },
  },
};
</script>
<style>
.vue-slider-dot-tooltip-text {
  color: inherit;
}
.v-data-table__wrapper {
  display: flex;
  flex-direction: column-reverse;
}
</style>
<style scoped>
.params-wrapper {
  display: flex;
  gap: 40px;
}
.params-item {
  flex-basis: 100px;
  flex-grow: 1;
}
@media (max-width: 960px) {
  .params-wrapper {
    display: block;
  }
}
</style>