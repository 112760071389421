<template>
  <div class="diagram-box" :style="calcColor">
    <div class="diagram-box__header">
      <div class="d-flex">
        <div class="range-values">
          <div class="value max">
            {{ maxValue }}
          </div>
          <div class="value min">
            {{ minValue }}
          </div>
        </div>
        <div class="range">
          <div class="field">
            <div class="slider-wrapper">
              <input
                type="range"
                :min="minValue"
                :max="maxValue"
                v-model="opt"
                :step="0.1"
                class="slider target-slider"
              />
            </div>
            <div class="slider-wrapper">
              <input
                type="range"
                :min="minValue"
                :max="maxValue"
                :value="currentValue"
                :step="0.1"
                disabled
                class="slider test-slider"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="diagram-box__title">
        <h3 class="el-title">{{ elem | capitalize }}</h3>

        <div class="el-values">
          <div class="test-tube">
            <div class="test-tube__label target__label">
              <span>Цель</span>
            </div>
            <div class="test-tube__value">
              <input
                type="number"
                v-model="opt"
                min="0"
                :max="maxValue"
                step="0.1"
              />
            </div>
          </div>
          <div class="test-tube">
            <div class="test-tube__label">
              <span>Проба</span>
            </div>
            <div class="test-tube__value">
              <span>{{ currentValue }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="diagram-box__footer" v-show="Object.keys(delta).length">
      <div v-if="elem != 'cev'">
        <div class="diagram-label">
          <div class="diagram-label__text">
            <span> Прирост: </span>
          </div>
          <div class="test-tube__value">
            <span>+{{ deltaValue }}</span>
          </div>
        </div>
        <div class="diagram-label">
          <div class="diagram-label__text">
            <span> Прогноз: </span>
          </div>
          <div class="test-tube__value">
            <span>
              {{ predictValue }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { isEmpty } from "lodash";

export default {
  name: "PredictionElement",

  components: {},
  props: {
    elem: {
      type: String,
    },
    historyElements: {
      type: Object,
    },
  },
  data() {
    return {
      success: "#64b77b",
      error: "#c93c54",
      warning: "#d7ca58",
    };
  },
  computed: {
    ...mapState([
      "delta",
      "prediction",
      "targets",
      "min",
      "max",
      "current_vals",
      "scale",
    ]),
    opt: {
      get() {
        return this.display(this.getOpt());
      },
      set(value) {
        if (isEmpty(this.historyElements)) {
          let val = +value;

          let payload = {
            element: this.elem,
            value: this.$deScale(val, this.elem),
          };

          this.SET_TARGET(payload);
        }
      },
    },
    minValue() {
      let min = !isEmpty(this.historyElements)
        ? this.historyElements.min[this.elem]
        : this.min[this.elem];

      return this.display(min);
    },
    maxValue() {
      let max = !isEmpty(this.historyElements)
        ? this.historyElements.max[this.elem]
        : this.max[this.elem];

      return this.display(max);
    },
    currentValue() {
      let currentValue = this.getCurrentValue();

      return this.display(currentValue);
    },
    deltaValue() {
      let delta = this.getDelta();

      if (delta) return this.display(delta);
    },
    predictValue() {
      let delta = this.getDelta();

      if (delta) return this.display(this.getCurrentValue() + delta);
    },
    calcColor() {
      let elem = this.elem;
      let rangeMaxVal = this.max[elem];
      let color = this.success;

      if (this.opt < this.min[elem]) rangeMaxVal = this.min[elem];

      if (this.current_vals[elem] < this.opt) color = this.warning;

      if (
        this.prediction[elem] <= rangeMaxVal &&
        this.prediction[elem] >= this.opt
      )
        color = this.success;

      if (this.current_vals[elem] > this.max[elem]) color = this.error;

      return {
        backgroundColor: color,
      };
    },
  },
  methods: {
    ...mapMutations(["SET_TARGET"]),
    getDelta() {
      return !isEmpty(this.historyElements)
        ? this.historyElements.delta[this.elem]
        : this.delta[this.elem];
    },
    getCurrentValue() {
      return !isEmpty(this.historyElements)
        ? this.historyElements.val[this.elem]
        : this.current_vals[this.elem];
    },
    getOpt() {
      return !isEmpty(this.historyElements)
        ? this.historyElements.opt[this.elem]
        : this.targets[this.elem];
    },
    display(value) {
      return this.$round(this.$scale(value, this.elem));
    },
  },
};
</script>

<style scoped>
.el-title {
  font-size: 32px;
  font-weight: 900;
  margin-bottom: 20px;
}
.el-values {
  width: 100%;
}
.diagram-box {
  padding: 10px 7px;
}
.diagram-box_success {
  background-color: #64b77b;
}

.diagram-box__header {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.diagram-box__footer {
  min-height: 71px;
}
.diagram-box__title {
  max-width: 90px;
  text-align: center;
}
.diagram-label {
  font-weight: 600;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;
}
.diagram-label__text {
  text-align: center;
}
.test-tube__label {
  background-color: #221f1f;
  color: #ebe3e3;
  font-size: 12px;
  font-weight: 300;
}
.target__label {
  background-color: #1976d2;
}
.test-tube {
  margin-bottom: 10px;
}
.test-tube__value {
  background-color: #ebe3e3;
  color: #000;
  font-size: 17px;
  font-weight: 900;
  text-align: center;
  width: 100%;
}
.test-tube__value input[type="number"] {
  width: 100%;
  text-align: center;
  padding-left: 13px;
}
.slider-wrapper {
  display: inline-block;
  width: 17px;
  height: 132px;
  padding: 0;
  position: relative;
}
.slider {
  width: 132px;
  height: 17px;
  margin: 0;
  transform-origin: 66px 66px;
  transform: rotate(-90deg);
}
.target-slider {
  -webkit-appearance: none;
  background-color: transparent;
  background-image: repeating-linear-gradient(
    90deg,
    transparent,
    transparent 2px,
    #ebe3e3 4px,
    #ebe3e3 10px
  );
}

.target-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 17px;
  width: 9px;
  background-color: #1976d2;
}

.test-slider {
  -webkit-appearance: none;
}

.test-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #000;
}
.range-values {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 132px;
  min-width: 43px;
}
@media (max-width: 599px) {
  .diagram-box {
    width: 100%;
  }
}
</style>