<template>
  <v-main>
    <router-view />
  </v-main>
</template>

<script>
import messages from "@/utils/messages";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "main-layout",
  computed: {
    ...mapState(["scale"]),
    ...mapState({
      e: "error",
      user: (s) => s.auth.user,
    }),
    error() {
      return this.e;
    },
  },
  async mounted() {
    try {
      await this.getElementsScale();
    } catch (e) {}
  },
  watch: {
    error(e) {
      if (e) {
        if (e === "timeout cookies") this.$router.push("/login?message=login");
        this.$error(messages[e] || "Что-то пошло не так...", "");
      }
    },
  },
  methods: {
    ...mapMutations(["SET_STATE"]),
    ...mapActions(["getElementsScale"]),
  },
};
</script>