<template>
  <div class="diagram-flexbox">
    <div class="diagram-label-wrapper">
      <div class="diagram-label">
        <v-icon color="black">mdi-arrow-collapse-up</v-icon>
        <span class="black--text">Max</span>
      </div>
      <div class="diagram-label">
        <v-icon color="primary">mdi-target</v-icon>
        <span class="primary--text">Цель</span>
      </div>
      <div class="diagram-label">
        <v-icon color="black">mdi-arrow-collapse-down</v-icon>
        <span class="black--text">Min</span>
      </div>
    </div>
    <div class="content">
      <div class="diagram-wrapper" v-show="range.length">
        <div v-for="elem in range" :key="elem" class="diagram-item">
          <prediction-element
            :elem="elem"
            :history-elements="historyElements"
          />
        </div>
      </div>
      <!-- <div v-show="loading" class="progress-wrapper">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PredictionElement from "./PredictionElement.vue";
export default {
  name: "Prediction",
  components: {
    PredictionElement,
  },
  props: {
    historyElements: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["range"]),
    // ...mapState({
    //   loading: (s) => s.settings.loading,
    // }),
  },
};
</script>

<style scoped>
.diagram-flexbox {
  display: flex;
  gap: 40px;
}
.diagram-wrapper {
  display: flex;
  gap: 25px;
  overflow-x: auto;
}
.diagram-item {
  min-width: 185px;
}
.diagram-label-wrapper {
  padding: 5px 0;
}
.diagram-label {
  display: flex;
  gap: 10px;
  padding: 5px 0;
}
.progress-wrapper {
  text-align: center;
  margin-top: 20px;
}
.content {
  width: calc(100% - 112px);
}

@media (max-width: 1264px) {
  .diagram-flexbox {
    display: block;
  }
  .diagram-label-wrapper {
    display: flex;
    gap: 20px;
  }

  .content {
    width: 100%;
  }
}
</style>