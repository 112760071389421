<template>
  <v-container>
    <v-row>
      <v-col cols="2">
        <v-combobox
          :items="aggr_names"
          item-text="aggrName"
          item-value="aggrName"
          label="Установка"
          v-model="f.aggr"
        ></v-combobox>
      </v-col>
      <v-col cols="2">
        <v-combobox
          multiple
          :items="elems"
          label="Элементы"
          v-model="f.elements"
        ></v-combobox>
      </v-col>
      <v-col cols="3">
        <v-combobox
          multiple
          :items="ferroItems"
          item-text="shortname"
          label="Ферросплавы"
          v-model="f.ferroalloys"
          :loading="ferrosLoading"
        ></v-combobox>
      </v-col>
      <v-col cols="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Период"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="f.dates"
            no-title
            scrollable
            locale="ru"
            range
          >
            <v-spacer></v-spacer>
            <!-- <v-btn text color="primary" @click="menu = false"> Отменить </v-btn> -->
            <v-btn text color="primary" @click="$refs.menu.save(f.dates)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-radio-group v-model="f.isExcel">
          <v-radio label="Сохранить в Excel" :value="true"></v-radio>
          <v-radio label="Отобразить на странице" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-btn
          color="primary"
          class="mb-2"
          @click="handleGettingReport()"
          :loading="reportLoading"
          :disabled="reportLoading"
          >Сгенерировать</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-show="report.length">
      <div style="overflow-x: auto">
        <table>
          <thead>
            <tr>
              <th rowspan="2">Плавка</th>
              <th :colspan="2 + f.elements.length">Проба</th>
              <th colspan="2" v-for="ferro in f.ferroalloys" :key="ferro.id">
                {{ ferro.shortname }}
              </th>
            </tr>
            <tr>
              <th colspan="2">Время</th>
              <th v-for="el in f.elements" :key="el">{{ el }}</th>
              <th v-for="(ferro, i) in fsHeader()" :key="i">{{ ferro }}</th>
            </tr>
          </thead>
          <tbody v-for="record in report" :key="record.id">
            <tr>
              <td rowspan="3">{{ record.heat }}</td>
              <td rowspan="3">{{ record.aggr }}</td>
              <td>{{ record.firstSample | date("datetime") }}</td>
              <td v-for="el in f.elements" :key="el">
                {{ record.value[el.toLowerCase()] | round }}
              </td>
              <td rowspan="3" v-for="(ferro, i) in fsData(record)" :key="i">
                {{ ferro }}
              </td>
            </tr>
            <tr>
              <td>Цель</td>
              <td v-for="el in f.elements" :key="el">
                {{ record.opt[el.toLowerCase()] | round }}
              </td>
            </tr>
            <tr>
              <td>{{ record.nextAggrSample | date("datetime") }}</td>
              <td v-for="el in f.elements" :key="el">
                {{ record.nextAggr[el.toLowerCase()] | round }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { elements } from "@/constants/elements";
import { serverApi } from "@/api/server";
import { mapMutations, mapState } from "vuex";
import { ferrosMixin } from "@/mixins/mixins";

export default {
  name: "Analytics",
  mixins: [ferrosMixin],
  data: () => ({
    f: {
      aggr: "",
      startDate: "",
      endDate: "",
      dates: [],
      elements: [],
      ferroalloys: [],
      isExcel: true,
    },
    menu: false,
    ferroItems: [],
    ferrosLoading: false,
    reportLoading: false,
    report: [],
  }),
  computed: {
    ...mapState(["work_params", "ferros", "aggr_names"]),
    elems() {
      return elements
        .filter((x) => x !== "cev")
        .map((x) => this.$capitalize(x));
    },
    dateRangeText() {
      if (new Date(this.f.dates[0]) > new Date(this.f.dates[1]))
        this.f.dates.reverse();

      return this.f.dates.map((date) => this.formatDate(date)).join(" ~ ");
    },
  },
  mounted() {
    this.getFerroItems();
  },
  methods: {
    ...mapMutations(["SET_STATE"]),
    fsHeader() {
      let output = [];

      this.f.ferroalloys.forEach((x) => {
        output.push("кальк.");
        output.push("факт");
      });

      return output;
    },
    fsData(record) {
      let output = [];

      this.f.ferroalloys.forEach((x) => {
        let calc = record.ferroalloyCalc.find((y) => x.id === y.id);
        if (calc) output.push(calc.mass);
        else output.push(0);

        let fact = record.ferroalloy.find((y) => x.id === y.id);

        if (!record.end) output.push("###");
        else {
          if (fact) output.push(fact.mass);
          else output.push(0);
        }
      });

      return output;
    },
    async getFerroItems() {
      this.ferroItems = [...this.ferros];

      if (!this.ferroItems.length) await this.getFerroalloys();
    },
    async getFerroalloys() {
      //this.ferrosLoading = true;

      try {
        let response = await serverApi.getFerroalloys();

        if (
          response.data &&
          response.data.length &&
          Array.isArray(response.data)
        )
          this.ferroItems = this.mapFerros(response.data);

        if (response.data && response.data === "error") this.$error();
      } catch (e) {
        this.SET_STATE({ error: e });
      } finally {
        //this.ferrosLoading = false;
      }
    },
    async handleGettingReport() {
      this.reportLoading = true;

      const formData = {
        aggr: this.f.aggr,
        startDate: this.f.dates[0],
        endDate: this.f.dates[1],
        elements: this.f.elements,
        ferroalloys: this.f.ferroalloys.map((x) => x.id),
        isExcel: this.f.isExcel,
      };

      if (this.f.isExcel) await this.downloadReport(formData);
      else await this.getReport(formData);
    },
    async getReport(formData) {
      this.report = [];
      try {
        let response = await serverApi.getReport(formData);

        if (response.data) {
          if (Array.isArray(response.data)) {
            if (response.data.length) this.report = response.data;
            else this.$error("Нет результата", "");
          }

          if (response.data === "error") this.$error();
        }
      } catch (e) {
        this.SET_STATE({ error: e });
      } finally {
        this.reportLoading = false;
      }
    },
    async downloadReport(formData) {
      try {
        let response = await serverApi.downloadReport(formData);

        this.download("отчет.xls", response);
      } catch (e) {
        this.SET_STATE({ error: e });
      } finally {
        this.reportLoading = false;
      }
    },
    download(title, response) {
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    formatDate(date) {
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
  },
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 10px;
}

td,
th {
  border: 1px solid #bfb8b8;
  text-align: center;
  padding: 8px;
}

tbody:nth-child(odd) {
  background-color: #dddddd;
}
</style>