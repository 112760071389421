import Vue from 'vue'
import { settingsApi } from '@/api/settings'
import { elements } from "@/constants/elements"
import { capitalizeFilter } from "@/filters/filters"

const defaultState = () => {
    return {
        setting_list: [],
        selected_setting: "",
        loading: false
    }
}

export default {
    state: defaultState,
    mutations: {
        SET_SETTINGS(state, settings) {
            state.setting_list = settings
        },
        SET_SETTING(state, setting) {
            state.selected_setting = setting
        },
        SET_LOADING(state, loading) {
            state.loading = loading
        },
        RESET_STATE(state) {
            Object.assign(state, defaultState())
        }
    },
    actions: {
        async fetchSettings({ dispatch, commit }, workParams) {
            commit('SET_LOADING', true)

            try {
                const response = await settingsApi.getSettings(workParams)

                if (response.data) {
                    if (response.data === "error") commit('SET_STATE', { error: response.data })

                    if (Object.keys(response.data).length) {
                        let data = response.data;

                        if (data.min && data.max && data.opt) {
                            let range = elements.filter(element => (data.min[element] > 0 && element !== "cev") || element === "p")

                            if (range.length == 1) range = [];

                            let payload = {
                                min: data.min,
                                max: data.max,
                                targets: data.opt,
                                range
                            };

                            commit('SET_STATE', payload)
                        }

                        if (data.elements) commit('SET_STATE', { coef: data.elements })

                        if (data.ferroalloys && data.ferroalloys.length && Array.isArray(data.ferroalloys)) {
                            let ferros = data.ferroalloys.map((x) => ({
                                mass: x.mass,
                                minMass: x.min_mass,
                                isUsed: x.isUse,
                                id: x.id,
                                shortname: x.shortname,
                                name: x.name,
                                off: x.off,
                                useroffrole: x.useroffrole,
                                price: x.price,
                                comp: Object.entries(x.elements)
                                    .filter(([key, value]) => value > 0)
                                    .map(([key, value]) => capitalizeFilter(key) + "=" + Vue.prototype.$round(value))
                                    .join("; "),
                            }));

                            commit('SET_STATE', { ferros })
                        }
                    }
                }
            } catch (e) {
                commit('SET_STATE', { error: e })
                throw e
            } finally {
                commit('SET_LOADING', false)
            }
        },
        async getSettingsList({ dispatch, commit }, { aggr }) {
            try {
                const response = await settingsApi.getSettingsList(aggr)

                if (response.data && response.data.length && Array.isArray(response.data)) commit("SET_SETTINGS", response.data);
            } catch (e) {
                commit('SET_STATE', { error: e })
                throw e
            }
        }
    }
}