import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: 'main', auth: true },
    component: Home
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: 'empty' },
    component: () => import('../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!store.state.auth.user.login) {
    try {
      await store.dispatch("getUser")
    } catch (e) { }
  }

  const authorized = store.state.auth.user.login
  const licenseIsTimeout = store.state.auth.user.licenseIsTimeout
  const requireAuth = to.matched.some(record => record.meta.auth)

  if (requireAuth && (!authorized || licenseIsTimeout)) {
    next('/login?message=login')
  } else {
    next()
  }
})

export default router
