<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="#3498db"
          class="white--text"
          block
          dark
          v-bind="attrs"
          v-on="on"
          @click="handleHistory"
        >
          История
        </v-btn>
      </template>

      <v-card>
        <v-toolbar dark color="cyan">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>История</v-toolbar-title>
          <v-progress-linear
            :active="historyLoading"
            :indeterminate="historyLoading"
            absolute
            bottom
            color="primary"
          ></v-progress-linear>
        </v-toolbar>
        <v-container v-for="calc in calculations" :key="calc.id">
          <v-row>
            <h3>РАСЧЕТ: {{ calc.dateTime | date("datetime") }}</h3>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <h3 class="mb-3">Прогноз</h3>
              <prediction
                :range="getRange(calc)"
                :history-elements="calc.elements"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <h3 class="mb-3">Рекомендация</h3>
              <rec :ferros="calc.ferroalloys" :lg="4"></rec>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { serverApi } from "@/api/server";
import { mapState, mapMutations } from "vuex";
import { rangeMixin } from "@/mixins/mixins";
import Prediction from "./Prediction";
import Rec from "./Rec";

export default {
  name: "CalcHistory",
  mixins: [rangeMixin],
  components: {
    Prediction,
    Rec,
  },
  data() {
    return {
      historyLoading: false,
      dialog: false,
    };
  },
  computed: {
    ...mapState(["melt_name", "aggr_name", "calculations"]),
  },
  methods: {
    ...mapMutations(["SET_STATE"]),
    handleHistory() {
      if (this.historyLoading) return;
      this.historyLoading = true;
      this.SET_STATE({ calculations: [] });

      const payload = {
        meltName: this.melt_name,
        aggrName: this.aggr_name,
      };

      serverApi.getHistory(
        payload,
        (response) => {
          this.SET_STATE({ calculations: response.data });
        },
        () => (this.historyLoading = false)
      );
    },
    getRange(calc) {
      return this.filterByMin(calc.elements.min);
    },
  },
};
</script>

<style>
</style>