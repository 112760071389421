import { http, getData, postData } from "./http"

const getMarks = async (data) => await http.get('api/workparams/getMarks', { params: data });
const getTestMarks = async (meltName) => await http.get(`api/workparams/getTestMark?head=${meltName}`);
const getWorkParams = async () => await http.get('api/workparams/getInitData');
const getReport = async (data) => await http.post('api/workparams/getReport', data);
const downloadReport = async (data) => await http.post('api/workparams/getReport', data, { responseType: "arraybuffer" });
const getFerroalloys = async () => await http.get('api/workparams/getFerroy');
const getOptimization = async (data) => await http.post('api/workparams/getOptimaze', data)
const getMelting = async (aggrName) => await http.get(`api/workparams/getHeat?id=${aggrName}`);
const getInfoData = async () => await http.get('api/workparams/getInfoData');
const getSlag = async () => await http.get('api/workparams/getSlag');
const setSlag = async (data) => await http.post('api/workparams/setSlag', data);
const getSlagOptimization = async (data) => await http.post('api/workparams/getSlagOptimization', data);
const getElementsScale = async () => await http.get('api/workparams/getElementsScale');

const getElements = ({ meltName, aggrName }, handler, callback) => getData('api/workparams/getElements',
    {
        head: meltName,
        aggr: aggrName
    },
    (res) => { handler(res) },
    () => { callback() });

const getHistory = ({ meltName, aggrName }, handler, callback) => getData('api/workparams/GetHistory',
    {
        haed: meltName,
        aggr: aggrName
    },
    (res) => { handler(res) },
    () => { callback() }
)

const getCalc = (data, handler) => postData('api/workparams/getCalc',
    data,
    (res) => { handler(res) }
)

export const serverApi = {
    getWorkParams,
    getMelting,
    getMarks,
    getElements,
    getElementsScale,
    getInfoData,
    getFerroalloys,
    getTestMarks,
    getCalc,
    getOptimization,
    getHistory,
    getReport,
    downloadReport,
    getSlag,
    setSlag,
    getSlagOptimization
}