<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h4>CaO, т - {{ countCao }}</h4>
        <h4>SiO2, т - {{ countSio2 }}</h4>
        <div class="wrapper">
          <span class="slag">B</span>
          <span class="text">{{ countB }}</span>
        </div>
        <div class="wrapper">
          <span class="slag">MgO, %</span>
          <span class="text">{{ countMgo }}</span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <slags
          :slags="slags"
          :headers="headers.filter((x) => x.value != 'si')"
          :loading="loadingSlags"
          :title="slagTitle"
          @edit="edit"
        ></slags>
      </v-col>
      <v-col cols="12" md="6">
        <slags
          :slags="mixture"
          :headers="headers"
          :loading="loadingSlags"
          :title="mixtureTitle"
          :isMixture="true"
          @edit="edit"
        ></slags>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Slags from "./Slags.vue";
import { slagsMixin } from "@/mixins/mixins";

export default {
  name: "SlagCalc",
  mixins: [slagsMixin],
  components: {
    Slags,
  },
  data: () => ({
    headers: [
      {
        text: "Наименование",
        sortable: false,
        value: "name",
      },
      {
        text: "Масса, т.",
        sortable: false,
        value: "mass",
      },
      {
        text: "CaO, %",
        sortable: false,
        value: "caO",
      },
      {
        text: "MgO, %",
        sortable: false,
        value: "mgO",
      },
      {
        text: "Si, %",
        sortable: false,
        value: "si",
      },
      {
        text: "SiO2, %",
        sortable: false,
        value: "siO2",
      },
      {
        text: "",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  computed: {
    countCao() {
      let result =
        this.slags.reduce((acc, obj) => {
          return acc + obj.mass * obj.caO;
        }, 0) /
          100 +
        this.mixture.reduce((acc, obj) => {
          return acc + obj.mass * obj.caO;
        }, 0) /
          100;

      return this.$round(result);
    },
    countSio2() {
      let result =
        ((60 *
          this.mixture.reduce((acc, obj) => {
            return acc + obj.si * obj.mass;
          }, 0)) /
          28 +
          this.mixture.reduce((acc, obj) => {
            return acc + obj.siO2 * obj.mass;
          }, 0) +
          this.slags.reduce((acc, obj) => {
            return acc + obj.siO2 * obj.mass;
          }, 0)) /
        100;

      return this.$round(result);
    },
    countB() {
      return this.$round(this.countCao / this.countSio2);
    },
    countMgo() {
      let result =
        this.slags.reduce((acc, obj) => {
          return acc + obj.mass * obj.mgO;
        }, 0) /
        this.slags.reduce((acc, obj) => {
          return acc + +obj.mass;
        }, 0);

      return this.$round(result);
    },
  },
};
</script>

