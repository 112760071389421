import { http } from "./http"

const loginUser = async (data) => await http.post('api/workparams/Logined', data)
const logout = async () => await http.get('api/workparams/logout')
const getCurrentUser = async () => await http.get('api/workparams/getUser')

const setLicenseKey = async (data) => await http.post('api/workparams/setLicenseKey', data)
const getLicenseExpDate = async () => await http.get('api/workparams/GetLecenseDateTo')

export const authApi = {
    loginUser,
    logout,
    getCurrentUser,
    setLicenseKey,
    getLicenseExpDate
}
