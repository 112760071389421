export const elements = [
    "c",
    "si",
    "mn",
    "p",
    "s",
    "cr",
    "ni",
    "cu",
    "ti",
    "al",
    "n",
    "v",
    "nb",
    "mo",
    "w",
    "as",
    "sb",
    "pb",
    "b",
    "co",
    "ca",
    "sn",
    "zr",
    "h",
    "zn",
    "bi",
    "cev",
]