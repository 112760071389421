<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="ranges"
      :loading="loading"
      loading-text="Загрузка..."
      :items-per-page="10"
      item-key="id"
      class="elevation-1"
      :noDataText="noDataText"
      :footer-props="footerSettings"
    >
      <template v-slot:item.min="props" v-if="isFull(min)">
        <v-edit-dialog
          large
          cancel-text="Отменить"
          save-text="Сохранить"
          @save="saveMin(props.item)"
          @open="editMin = props.item.min"
        >
          {{ props.item.min }}
          <template v-slot:input>
            <div class="mt-4 text-h6">Min</div>
            <v-text-field
              v-model="editMin"
              maxlength="10"
              required
              label="Edit"
              single-line
              counter
              :rules="[rules.digit]"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.max="props" v-if="isFull(max)">
        <v-edit-dialog
          large
          cancel-text="Отменить"
          save-text="Сохранить"
          @save="saveMax(props.item)"
          @open="editMax = props.item.max"
        >
          {{ props.item.max }}
          <template v-slot:input>
            <div class="mt-4 text-h6">Max</div>
            <v-text-field
              v-model="editMax"
              maxlength="10"
              required
              label="Edit"
              single-line
              counter
              :rules="[rules.digit]"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.opt="props" v-if="isFull(targets)">
        <v-edit-dialog
          large
          cancel-text="Отменить"
          save-text="Сохранить"
          @save="saveOpt(props.item)"
          @open="editOpt = props.item.opt"
        >
          {{ props.item.opt }}
          <template v-slot:input>
            <div class="mt-4 text-h6">Цель</div>
            <v-text-field
              v-model="editOpt"
              maxlength="10"
              required
              label="Edit"
              single-line
              counter
              :rules="[rules.digit]"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.coef="props">
        <v-edit-dialog
          large
          cancel-text="Отменить"
          save-text="Сохранить"
          @save="saveCoef(props.item)"
          @open="editCoef = props.item.coef"
        >
          {{ props.item.coef }}
          <template v-slot:input>
            <div class="mt-4 text-h6">Коеф. усвоения</div>
            <v-text-field
              v-model="editCoef"
              maxlength="10"
              required
              label="Edit"
              single-line
              counter
              :rules="[rules.digit]"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "FerroSettings",
  props: {
    ranges: {
      type: Array,
    },
  },
  data: function () {
    return {
      loading: false,
      editMin: "",
      editMax: "",
      editOpt: "",
      editCoef: "",
      rules: {
        digit: (value) => {
          return this.validate(value) || "Введите числовое значение";
        },
      },
      headers: [
        {
          text: "Элемент",
          sortable: false,
          value: "elem",
        },
        {
          text: "Min",
          sortable: false,
          value: "min",
        },
        {
          text: "Max",
          sortable: false,
          value: "max",
        },
        {
          text: "Цель",
          sortable: false,
          value: "opt",
        },
        {
          text: "Коеф. усвоения",
          sortable: false,
          value: "coef",
        },
      ],
      noDataText: "Данные отсутствуют",
      footerSettings: {
        itemsPerPageText: "Отображать",
        itemsPerPageAllText: "Все",
      },
    };
  },
  computed: {
    ...mapState(["min", "max", "targets"]),
    ...mapState({
      user: (s) => s.auth.user,
    }),
  },
  methods: {
    ...mapMutations([
      "SET_STATE",
      "SET_MIN",
      "SET_MAX",
      "SET_TARGET",
      "SET_COEF",
    ]),
    saveMin(item) {
      if (this.validate(this.editMin)) {
        const element = item.elem.toLowerCase();
        const value = this.$round(this.editMin);

        item.min = value;

        this.SET_MIN({
          element,
          value,
        });
      }
    },
    saveMax(item) {
      if (this.validate(this.editMax)) {
        const element = item.elem.toLowerCase();
        const value = this.$round(this.editMax);

        item.max = value;

        this.SET_MAX({
          element,
          value,
        });
      }
    },
    saveOpt(item) {
      if (this.validate(this.editOpt)) {
        const element = item.elem.toLowerCase();
        const value = this.$round(this.editOpt);

        item.opt = value;

        this.SET_TARGET({
          element,
          value,
        });
      }
    },
    saveCoef(item) {
      if (this.validate(this.editCoef)) {
        const element = item.elem.toLowerCase();
        const value = this.$round(this.editCoef);

        item.coef = value;

        this.SET_COEF({
          element,
          value,
        });
      }
    },
    validate(value) {
      const pattern = /^-?\d+(\.\d+)?$/;
      return pattern.test(value);
    },
    isFull(value) {
      return Object.keys(value).length;
    },
  },
};
</script>

<style scoped>
</style>