import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Notifications from 'vue-notification'
import { roundFilter, scaleFilter, capitalizeFilter, dateFilter } from "@/filters/filters"
import { scalePlugin, roundPlugin, messagePlugin, capitalizePlugin } from '@/utils/plugins'

Vue.config.productionTip = false

Vue.filter("round", roundFilter)
Vue.filter("scale", scaleFilter)
Vue.filter("capitalize", capitalizeFilter)
Vue.filter("date", dateFilter)

Vue.use(scalePlugin)
Vue.use(roundPlugin)
Vue.use(messagePlugin)
Vue.use(capitalizePlugin)
Vue.use(Notifications)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
