import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import settings from './settings'
import { serverApi } from '@/api/server'

Vue.use(Vuex)

const defaultState = () => {
  return {
    additive: [],
    delta: {},
    prediction: {},
    delta_ferros: [],
    mass: "",
    melt_name: "",
    aggr_name: "",
    mark_name: "",
    letter_name: "",
    comp_name: "",
    targets: {},
    current_vals: {},
    min: {},
    max: {},
    coef: {},
    test_marks: [],
    test_marks_loading: false,
    selected_test_mark: [],
    steel_mass_error: {
      error: false,
      message: "",
    },
    calculations: [],
    scale: {},
    error: null,
    ferros: [],
    ferros_loading: false,
    range: [],
    selected_coef: "",
    work_params: {},
    melts: [],
    calculating: false,
    aggr_names: [],
    slagItems: [],
    loadingSlags: false
  }
}

export default new Vuex.Store({
  state: defaultState,
  mutations: {
    SET_TARGET(state, payload) {
      state.targets[payload.element] = payload.value
    },
    SET_MIN(state, payload) {
      state.min[payload.element] = payload.value
    },
    SET_MAX(state, payload) {
      state.max[payload.element] = payload.value
    },
    SET_COEF(state, payload) {
      state.coef[payload.element] = payload.value
    },
    CLEAR_ERROR(state) {
      state.error = null
    },
    RESET_STATE(state) {
      Object.assign(state, defaultState())
    },
    SET_STATE(state, payload) {
      if (!payload) state = defaultState
      for (let key in payload) state[key] = payload[key]
    }
  },
  actions: {
    async getWorkParams({ dispatch, commit }) {
      try {
        let response = await serverApi.getWorkParams();
        let workParams = response.data

        if (workParams && workParams.aggregates) {

          let payload = {
            work_params: workParams,
            aggr_names: workParams.aggregates.map((x) => {
              if (x.aggrName.toLowerCase().startsWith("укп"))
                return x.aggrName.slice(0, -2);
              else return x.aggrName;
            })
          }

          commit('SET_STATE', payload)
        }

      } catch (e) {
        commit('SET_STATE', { error: e })
        throw e
      }
    },
    async getInfoData({ dispatch, commit, state }) {
      try {
        let response = await serverApi.getInfoData();

        if (
          response.data &&
          response.data.length &&
          Array.isArray(response.data)
        ) {
          let result = response.data.filter(
            (x) => x["steel_grade"] === state.mark_name
          );

          let payload = {}

          if (result && result.length) {
            payload.letter_name = result[0]["letter_num"]
            payload.comp_name = result[0]["std_chemistry"]
          } else {
            payload.letter_name = ""
            payload.comp_name = ""
          }

          commit('SET_STATE', payload)
        }

        if (response.data && response.data === "error") commit('SET_STATE', { error: response.data })
      } catch (e) {
        commit('SET_STATE', { error: e })
        throw e
      }
    },
    async getMarks({ dispatch, commit, state }, coefItems) {
      try {
        let response = await serverApi.getMarks({ id: state.melt_name, aggr: state.aggr_name });

        if (response.data) {
          if (response.data === "error") commit('SET_STATE', { error: response.data })

          if (response.data.length && Array.isArray(response.data)) {
            let payload = {
              mark_name: response.data[0],
              letter_name: response.data[2],
              comp_name: response.data[3],
              mass: response.data[5],
              selected_coef: coefItems[1]
            };

            commit('SET_STATE', payload)
          }
        }
      } catch (e) {
        commit('SET_STATE', { error: e })
        throw e
      }
    },
    async getMelting({ dispatch, commit, state }, { aggr }) {
      try {
        let response = await serverApi.getMelting(aggr);

        if (
          response.data &&
          response.data.length &&
          Array.isArray(response.data)
        ) {
          let payload = {
            melts: response.data,
            melt_name: response.data[0]
          }

          commit('SET_STATE', payload);
        }

        if (response.data && response.data === "error") commit('SET_STATE', { error: response.data })
      } catch (e) {
        commit('SET_STATE', { error: e })
        throw e
      }
    },
    async updateMelting({ dispatch, commit, state }) {
      try {
        let response = await serverApi.getMelting(state.aggr_name);

        if (
          response.data &&
          response.data.length &&
          Array.isArray(response.data)
        ) {
          if (state.melts[0] != response.data[0]) {
            let payload = {
              melts: response.data
            }

            if (state.melt_name) payload.melt_name = response.data[1]

            commit('SET_STATE', payload);
          }
        }
      } catch (e) {
        commit('SET_STATE', { error: e })
        throw e
      }
    },
    async getTestMarks({ dispatch, commit, state }) {
      commit('SET_STATE', { test_marks_loading: true })

      try {
        let response = await serverApi.getTestMarks(state.melt_name);

        if (
          response.data &&
          response.data.length &&
          Array.isArray(response.data)
        ) {
          let elems = response.data.map((x, index) => ({
            index,
            date: x.date,
            datestr: x.datestr,
            ...Object.fromEntries(
              Object.entries(x.elements).map(([key, value]) => [
                key,
                Vue.prototype.$round(Vue.prototype.$scale(value, key)),
              ])
            ),
          }));

          let currentVals = response.data[response.data.length - 1].elements

          let payload = {
            test_marks: elems,
            current_vals: currentVals,
            selected_test_mark: [elems[elems.length - 1]],
          };

          commit('SET_STATE', payload)
        }

        if (response.data && response.data === "error") commit('SET_STATE', { error: response.data })
      } catch (e) {
        commit('SET_STATE', { error: e })
        throw e
      } finally {
        commit('SET_STATE', { test_marks_loading: false })
      }
    },
    async updateTestMarks({ dispatch, commit, state }) {
      try {
        let response = await serverApi.getTestMarks(state.melt_name);

        if (
          response.data &&
          response.data.length &&
          Array.isArray(response.data)
        ) {

          if (new Date(state.test_marks[0].date) < new Date(response.data[0].date)) {
            let elems = response.data.map((x, index) => ({
              index,
              date: x.date,
              datestr: x.datestr,
              ...Object.fromEntries(
                Object.entries(x.elements).map(([key, value]) => [
                  key,
                  Vue.prototype.$round(Vue.prototype.$scale(value, key)),
                ])
              ),
            }));

            let currentVals = response.data[response.data.length - 1].elements

            let payload = {
              test_marks: elems,
              current_vals: currentVals,
              selected_test_mark: [elems[elems.length - 1]],
            };

            commit('SET_STATE', payload)

            if (state.range.length && !state.steel_mass_error.error) {
              await dispatch("optimize")
            }
          }
        }
      } catch (e) {
        commit('SET_STATE', { error: e })
        throw e
      }
    },
    async optimize({ dispatch, commit, state }) {
      commit('SET_STATE', { calculating: true, error: null })
      let testMark = state.selected_test_mark[0].index;

      if (state.targets && Object.keys(state.targets).length) {
        let { cev, ...elems } = state.targets;

        let data = Object.entries(elems).map((x) => `${x[0]}:${x[1]}`);

        let payload = {
          targets: data,
          mass: state.mass,
          testMark,
          meltName: state.melt_name,
          aggr: state.aggr_name,
          isHandCoef: state.selected_coef.value,
          mark: state.mark_name,
          letter: state.letter_name,
          chemistry: state.comp_name,
        };

        try {
          let response = await serverApi.getOptimization(payload);

          if (response.data && Array.isArray(response.data)) {
            if (response.data.length === 0) {
              commit('SET_STATE', {
                delta: {},
                prediction: {},
                delta_ferros: [],
                error: "optNoResult"
              })

              return;
            }

            let payload = {
              current_vals: response.data[1][0],
              delta: response.data[1][1],
              prediction: response.data[1][2],
              delta_ferros: response.data[0],
            };

            commit('SET_STATE', payload)
          }

          if (response.data && response.data === "error") commit('SET_STATE', { error: response.data })
        } catch (e) {
          commit('SET_STATE', { error: e })
          throw e
        } finally {
          commit('SET_STATE', { calculating: false })
        }
      }
    },
    async getElementsScale({ dispatch, commit }) {
      try {
        let response = await serverApi.getElementsScale();

        if (response.data && response.data.length) {
          if (Array.isArray(response.data)) {
            const initialValue = {};

            let scale = response.data.reduce(
              (obj, item) => ({
                ...obj,
                [item.key.toLowerCase()]: item.value,
              }),
              initialValue
            );

            commit('SET_STATE', { scale })
          }

          if (response.data === "error") {
            commit('SET_STATE', { error: response.data })
          }
        }

      } catch (e) {
        commit('SET_STATE', { error: e })
        throw e
      }
    },
    async fetchSlag({ dispatch, commit }) {
      commit('SET_STATE', { loadingSlags: true })

      try {
        const response = await serverApi.getSlag();

        if (response.data && response.data.length) {
          if (Array.isArray(response.data)) commit('SET_STATE', {
            slagItems: response.data.map((x) => ({
              caO: x.caO,
              id: x.id,
              mass: x.mass,
              mgO: x.mgO,
              name: x.name,
              si: x.si,
              siO2: x.siO2,
              maxMass: x.si !== null ? "0" : 'максимум',
              resultMass: 0
            }))
          })

          if (response.data === "error") commit('SET_STATE', { error: response.data })
        }
      } catch (e) {
        commit('SET_STATE', { error: e })
        throw e
      } finally {
        commit('SET_STATE', { loadingSlags: false })
      }
    },
  },
  modules: {
    auth,
    settings
  }
})
