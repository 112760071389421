export const rangeMixin = {
    methods: {
        filterByMin(min) {
            let elems = Object.entries(min)
                .filter((x) => x[1] > 0 || x[0] === "p")
                .map((el) => el[0]);

            if (elems.length == 1) elems = [];

            return elems;
        }
    }
}