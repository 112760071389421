<template>
  <v-row class="rec-row">
    <v-col
      cols="12"
      :sm="sm"
      :md="md"
      :lg="lg"
      :xl="xl"
      v-for="item in ferros"
      :key="item.id"
    >
      <v-card elevation="6" class="card">
        <v-card-text>
          <p class="text--primary short-name">{{ item.shortname }}</p>
          <p class="text-h4">{{ item.mass }} кг</p>
          <div class="d-flex flex-wrap additive-box">
            <div
              v-for="elemDelta in filterDelta(item.delta)"
              :key="elemDelta[0]"
            >
              <div class="mr-2 mb-1 additive-chip">
                <span>{{ elemDelta[0] | capitalize }}</span>
                <span>+{{ elemDelta[1] | round }}</span>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Rec",
  props: {
    ferros: {
      type: Array,
    },
    sm: {
      type: String | Number,
      default: "6",
    },
    md: {
      type: String | Number,
      default: "4",
    },
    lg: {
      type: String | Number,
      default: "3",
    },
    xl: {
      type: String | Number,
      default: "3",
    },
  },
  computed: {
    ...mapState(["delta_ferros"]),
  },
  methods: {
    filterDelta(delta) {
      return Object.entries(delta).filter((x) => x[1] > 0);
    },
  },
};
</script>

<style scoped>
.additive-box {
  max-width: 220px;
}
.additive-chip {
  background-color: #1976d2;
  color: #fff;
  padding: 2px 13px;
  min-width: 100px;
  display: flex;
  justify-content: space-between;
}
.short-name {
  font-size: 32px;
  font-weight: 700;
  line-height: 1;
}
.card {
  min-height: 100%;
}
.rec-row {
  flex-wrap: nowrap;
  overflow-x: auto;
  margin: 0;
}
</style>