<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    @submit.prevent="handleOptimization"
  >
    <v-container>
      <v-row>
        <v-col cols="12">
          <h2>Рабочие параметры</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <v-card elevation="2">
            <v-card-subtitle>
              <div class="wrapper">
                <span class="slag">B</span>
                <span class="text" v-show="countB">{{ countB }}</span>
              </div>
            </v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    type="number"
                    min="0"
                    step="0.1"
                    v-model="bMin"
                    label="от"
                    :rules="digitRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    type="number"
                    min="0"
                    step="0.1"
                    v-model="bMax"
                    label="до"
                    :rules="digitRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2">
          <v-card>
            <v-card-subtitle>
              <div class="wrapper">
                <span class="slag">MgO, %</span>
                <span class="text" v-show="countMgo"> {{ countMgo }}</span>
              </div>
            </v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    type="number"
                    min="0"
                    step="0.1"
                    v-model="mgOMin"
                    label="от"
                    :rules="digitRules"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    type="number"
                    min="0"
                    step="0.1"
                    v-model="mgOMax"
                    label="до"
                    :rules="digitRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-show="mixtureMass">
          <v-card>
            <v-card-subtitle>Суммарная масса шихты, т.</v-card-subtitle>
            <v-card-text>
              <h3>{{ mixtureMass }}</h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <slags
            :slags="slags"
            :headers="getHeaders(false).filter((x) => x.value != 'si')"
            :loading="loadingSlags"
            :title="slagTitle"
            @edit="edit"
            mainCalc
          ></slags>
        </v-col>
        <v-col cols="12" md="6">
          <slags
            :slags="mixture"
            :headers="getHeaders(true)"
            :loading="loadingSlags"
            :title="mixtureTitle"
            isMixture
            @edit="edit"
            mainCalc
          ></slags>
        </v-col>
      </v-row>
      <v-row v-if="slagItems.length">
        <v-col cols="6" md="2">
          <v-btn
            type="submit"
            color="#3498db"
            class="white--text mt-5"
            block
            :loading="calculating"
            :disabled="!valid || calculating"
            @click="validate"
            >Оптимизировать
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { serverApi } from "@/api/server";
import { mapMutations } from "vuex";
import Slags from "./Slags.vue";
import { slagsMixin } from "@/mixins/mixins";

export default {
  name: "SlagMainCalc",
  components: {
    Slags,
  },
  mixins: [slagsMixin],
  data() {
    return {
      dialog: false,
      valid: true,
      calculating: false,
      bMin: 1.9,
      bMax: 2.1,
      mgOMin: 8,
      mgOMax: 10,
      digitRules: [(v) => this.validateDigit(v) || "Введите числовое значение"],
    };
  },
  computed: {
    mixtureMass() {
      return this.mixture.reduce((acc, obj) => {
        return acc + +obj.maxMass;
      }, 0);
    },
    countCao() {
      let result =
        this.slags.reduce((acc, obj) => {
          return acc + obj.resultMass * obj.caO;
        }, 0) /
          100 +
        this.mixture.reduce((acc, obj) => {
          return acc + obj.resultMass * obj.caO;
        }, 0) /
          100;

      return this.$round(result);
    },
    countSio2() {
      let result =
        ((60 *
          this.mixture.reduce((acc, obj) => {
            return acc + obj.si * obj.resultMass;
          }, 0)) /
          28 +
          this.mixture.reduce((acc, obj) => {
            return acc + obj.siO2 * obj.resultMass;
          }, 0) +
          this.slags.reduce((acc, obj) => {
            return acc + obj.siO2 * obj.resultMass;
          }, 0)) /
        100;

      return this.$round(result);
    },
    countB() {
      return this.$round(this.countCao / this.countSio2);
    },
    countMgo() {
      let result =
        this.slags.reduce((acc, obj) => {
          return acc + obj.resultMass * obj.mgO;
        }, 0) /
        this.slags.reduce((acc, obj) => {
          return acc + +obj.resultMass;
        }, 0);

      return this.$round(result);
    },
  },
  methods: {
    ...mapMutations(["SET_STATE"]),
    getHeaders(isMixture) {
      let name = {
        text: "Наименование",
        sortable: false,
        value: "name",
      };

      let actions = {
        text: "",
        value: "actions",
        sortable: false,
      };

      let elems = [
        {
          text: "CaO, %",
          sortable: false,
          value: "caO",
        },
        {
          text: "MgO, %",
          sortable: false,
          value: "mgO",
        },
        {
          text: "Si, %",
          sortable: false,
          value: "si",
        },
        {
          text: "SiO2, %",
          sortable: false,
          value: "siO2",
        },
      ];

      let output = isMixture
        ? [
            { ...name },
            {
              text: "Масса, т.",
              sortable: false,
              value: "maxMass",
            },
            ...elems,
            { ...actions },
          ]
        : [
            { ...name },
            {
              text: "Доступная масса, т.",
              sortable: false,
              value: "maxMass",
            },
            ...elems,
            {
              text: "Результирующая масса, т.",
              sortable: false,
              value: "resultMass",
            },
            { ...actions },
          ];

      return output;
    },
    async handleOptimization() {
      this.calculating = true;

      let formData = {
        mgo: [this.mgOMin, this.mgOMax],
        b: [this.bMin, this.bMax],
        slags: this.slagItems,
      };

      try {
        let response = await serverApi.getSlagOptimization(formData);

        if (response.data && response.data.length) {
          if (Array.isArray(response.data)) {
            let resultData = response.data;

            resultData.forEach((x) => {
              this.slagItems.forEach((y) => {
                if (x.id === y.id) {
                  x.maxMass = y.maxMass;
                  x.resultMass = x.mass;
                  x.mass = y.mass;
                }
              });
            });

            this.SET_STATE({ slagItems: resultData });
          }

          if (response.data === "error") this.$error();
        }
      } catch (e) {
        this.SET_STATE({ error: e });
      } finally {
        this.calculating = false;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    validateDigit(value) {
      const pattern = /^\d+(\.\d+)?$/;
      return pattern.test(value);
    },
  },
};
</script>