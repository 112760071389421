export default {
    install(Vue, options) {
        Vue.prototype.$error = function (text = "Что-то пошло не так...", title = "Произошла ошибка") {
            this.$notify({
                group: "foo",
                type: "error",
                title,
                text
            });
        }

        Vue.prototype.$message = function (text) {
            this.$notify({
                group: "foo",
                type: "inform",
                text,
            });
        }
    }
}