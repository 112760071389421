import { http } from "./http"


const getSettingsList = async (aggr) => await http.get(`api/workparams/getSettingsList?aggr=${aggr}`)
const getSettings = async (data) => await http.get('api/workparams/getSettings', { params: data })
const saveSettings = async (data) => await http.post('api/workparams/SaveSettings', data)

export const settingsApi = {
    getSettings,
    saveSettings,
    getSettingsList
}